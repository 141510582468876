import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { setTokenToStorage } from "../../../utils";
import { api } from "../../../resources/api";
import { FormNames, WindowNames } from "../../../resources/constants";
import { AuthDataType, IRespData, RegDataType, testPhone } from "./types";
import { LOADIPHLPAPI } from "dns";
import { resolveObjectURL } from "buffer";
import { da } from "date-fns/locale";
import {
  ICodeResp,
  IFetchCodeArgs,
  IFetchReqCodeArgs,
} from "../settings/changePass/types";

export const fetchAuth = createAsyncThunk<IRespData, AuthDataType>(
  "authAndReg/fetchAuth",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.auth.LOGIN}`;

      const response = await axios.post(url, data);
      console.log(response)

      if (response.status === 200) {
        setTokenToStorage(response.data.token);
        return { showWindow: WindowNames.AUTH_SUCCESS, auth: true };
      } else {
        return { showWindow: WindowNames.ERROR, auth: false };
      }
    } catch (error: any) {
      return error.response.status === 422
        ? { showWindow: WindowNames.AUTH_ERROR, auth: false }
        : { showWindow: WindowNames.ERROR, auth: false };
    }
  }
);

export const fetchReg = createAsyncThunk<IRespData, RegDataType>(
  "authAndReg/fetchReg",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.registration.REG}`;

      const response = await axios.post(url, data.values);

      if (response.status === 200 && response.statusText === "OK") {
        data.actions.setSubmitting(false);
        return { showWindow: WindowNames.CODE, auth: false };
      } else {
        data.actions.setSubmitting(false);
        return { showWindow: WindowNames.ERROR, auth: false };
      }
    } catch (error: any) {
      if (error.response.data.errors) {
        const errors: { [key: string]: string } = {};

        for (let key in error.response.data.errors) {
          errors[key] = error.response.data.errors[key][0];
        }
        data.actions.setErrors(errors);
        data.actions.setSubmitting(false);
        return { auth: false };
      } else {
        return { showWindow: WindowNames.ERROR, auth: false };
      }
    }
  }
);

export const fetchRequestCode = createAsyncThunk<IRespData, testPhone>(
  "reg/fetchReqCode",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.settings.changePass.REQUEST_CODE}`;
      const response = await axios.post(url, data);
      if (response.status === 200) {
        return { showWindow: WindowNames.REGISTRATION_SUCCESS, auth: true };
      } else {
        return { showWindow: WindowNames.ERROR, auth: false };
      }
    } catch (error: any) {
      return error.response.status === 422
        ? { showWindow: WindowNames.AUTH_ERROR, auth: false }
        : { showWindow: WindowNames.ERROR, auth: false };
    }
  }
);

export const fetchApplyCode = createAsyncThunk<IRespData, IFetchCodeArgs>(
  "changePass/fetchSendCode",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.settings.changePass.SEND_CODE}`;
      const response = await axios.post(url, data);
      setTokenToStorage(response.data.token);
      if (response.status === 200) {
        return { showWindow: WindowNames.REGISTRATION_SUCCESS, auth: true };
      } else {
        return { showWindow: WindowNames.ERROR, auth: false };
      }
    } catch (error: any) {
      return error.response.status === 422
        ? { showWindow: WindowNames.ERROR_CODE, auth: false }
        : { showWindow: WindowNames.ERROR, auth: false };
    }
  }
);
