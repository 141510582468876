import dateFormat from "dateformat";

export const differenceDate = (dateNow: Date, dateTested: Date) => {
  return +dateNow - +dateTested;
};

export const dateRestFormat = (date: Date) => {
  const formatDate = dateFormat(date, "yyyy-mm-dd'T'HH:MM:ssp");
  return formatDate;
};
