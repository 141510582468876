export const api = {
  // BASE_URL: "https://admin.tennis.ru/",
  BASE_URL: "https://admin-fitness.ru/",
  subscription: {
    GET_SUBSCRIPTIONS: "api/subscriptions/new", // GET
  },
  main: {
    GET_CLUB_IMAGES: "api/getClubImages", // GET
    GET_HALLS_IMAGES: "api/getHallMainImages", // GET // Dont work
  },
  halls: {
    GET_HALLS: "api/getHalls", // GET
    GET_HALL: (hallId: number, date: string) => `api/getHall/${hallId}/${date}`, // GET -> hallId: '1', date: 'YYYY-MM-DD'
    GET_HALLSHISTORY: "api/profile/hallsHistory",
  },
  // =========== //
  profile: {
    auth: {
      LOGIN: "api/login", // Post -> body: { phone: "+7 (111) 111-11-11", password: '111111'}
    },
    registration: {
      REG: "api/register", // Post -> body {name: "Abc", email: "abc@abc.ab", phone: "+7 (111) 111-11-11", password: "111111", passwordRepeat: "111111",}
    },
    settings: {
      changePass: {
        SEND_PHONE: "api/resetPassword", // Post -> body:  { phone: "+7 (111) 111-11-11"}
        SEND_CODE: "api/checkCode", // Post -> body: { phone: "+7 (111) 111-11-11", code: "1111" }
        SEND_NEW_PASSWORD: "api/storeResetPassword", // Post -> body: { phone: "+7 (111) 111-11-11", password: "111111", passwordRepeat: "111111" }
        CHANGE_PASS: "/api/settings/password",
        REQUEST_CODE : "/api/requestCode", // Post -> body { phone: "+7 (111) 111-11-11"}
      },
      getProfile: {
        DEL_PROFILE: "api/profiles/delete",
        GET_PROFILE: "/api/user", /// Get -> headers: Authorization 'token'
        GET_TYPE: (type: string) => `/api/profiles/create/${type}`,
        GET_TYPE_PROFILE: (typeProfile: string) => `/api/profiles/${typeProfile}`,
      },
      changeName: {
        CHANGE_NAME: "/api/settings/name", /// Post -> body: { name: "teststring"}
      },
      changeEmail: {
        GET_CODE_EMAIL: "/api/settings/email", /// Post -> body: { email: "testemail"}
        CHANGE_EMAIL: "/api/settings/checkEmailChangeCode", /// Post -> body : {email: "testmail, code: "1111}
      },
      changePhone: {
        CHANGE_PHONE: "/api/settings/phone", /// Post -> body: {phone: "+7 (111) 111-11-11"}
      },
    },
    cards: {
      GET_TOKEN: "api/profile/addCard", /// api/profile/addCard // Get -> headers: Authorization 'token'
      GET_CARD: "/api/profile/cards", /// Get -> headers: Authorization 'token'
      DELETE_CARD: "/api/profile/cards/remove/", /// /// Get -> headers: Authorization 'token' + IDcard in Body
    },
    history: {
      GET_PURCHASE_HISTORY: "/api/profile/purchaseHistory",
      GET_SUBSCRIPTION_HISTORY: "/api/profile/subscriptionsHistory", /// Get -> headers: Authorization 'token'
    },
  },
  rent: {
    courts: {
      GET_COURTS: (date: string) => `api/arena/${date}/new`, /// Get -> date: 'yyyy-mm-dd'
    },
    rackets: {
      GET_RACKETS: (date: string) => `/api/getRacketTime/${date}`, /// Get -> date: 'yyyy-mm-dd'
    },
  },
  cart: {
    ADD_RESERVE: "api/addReserve", /// Post -> body: {cartTotal: { "rackets": {}, "seats": {"150-4-1-2023-02-28": {"arenaId": 1,"arenaTitle": "Хард, первый корпус","courtId": 4,"courtTitle": "Корт 1","court_id": 4,"date": "2023-02-28","id": 150,"is_special_price": true,"price": 1150,"price_weekend": 1150,"startTimeCourt": "2023-02-12T17:52:56+03:00","time": "23:00",}}, headers: {Authorization: "Bearer " + token,},
    DELETE_FROM_CART: "api/deleteFromCart", /// Post -> body: {delArr: objects like ADD_RESERVE}},headers: {Authorization: "Bearer " + token,},
  },
  kassa: {
    GET_URL: (tokenData: string) =>
      `https://fitness.tennis.ru/api/payCardWeb/${tokenData}`,
  },
  payment: {
    GET_PAYMENT_URL: (tokenData: string) =>
      `https://fitness.tennis.ru/api/payWeb/${tokenData}`,
    CREATE_PAYMENT_LINKED_CARD: "api/payCart",
  },
  feedback: {
    FEEDBACK: "api/feedback", // Post -> { name: "Иван", email: "abc@abc.ru", phone: "+7 (111) 111-11-11", message: "bla bla" }
  },
  school: {
    TENNIS_SCHOOL: "api/personalChildTrainingOrder",
    PERSONAL_TRAINING: "api/personalTrainingOrder",
  },
  news: {
    GET_NEWS: (limit: number) => `/api/news/${limit}`,
  },
};
